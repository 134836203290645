import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import DashBoard from "./screens/DashBoard";
import Home from "./screens/Home";
import Login from "./screens/Login";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import AdminPanel from "./screens/AdminPanel";
import { parseJwt } from "./utils/constants";

export const Context = React.createContext();

export default function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const [isAdmin, setAdmin] = useState(false);
   const [userName, setUserName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userSelect, setUserSelect] = useState(null);
  const [userAgrType, setUserAgrType] = useState(null);
  const [treeId, setTreeId] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [userView,setUserView]=useState(false)
  const [userSelectView,setUserSelectView]=useState(false)
  
  const adminInitializer=()=>{
    const data = parseJwt(localStorage.getItem("echo_hex_token"));
    if (data.authorities.includes("Admin")
        ||data.authorities.includes("admin")
      ||data.authorities.includes("supersAdmin")) {
      setAdmin(true)}
  }

  useEffect(()=>{
    localStorage.getItem("echo_hex_token") && adminInitializer()
    setUploadImage('')
    setUserName(localStorage.getItem('name'))
    setUserId(localStorage.getItem('userId'))
    setUserSelect(localStorage.getItem('user_select'))
    setUserAgrType(localStorage.getItem('user_argr_type'))
    setTreeId(localStorage.getItem('treeId'))
  },[])
  
  const contextValue = {
    windowWidth,
    windowHeight,
    isAdmin,
    setAdmin,
    userSelectView,setUserSelectView,
    uploadImage, setUploadImage,
    userView,setUserView,
    userName,
    setUserName,
    userId,
    setUserId,
    userSelect,
    setUserSelect,
    userAgrType,
    setUserAgrType,
    treeId,
    setTreeId,
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  return (
    <div>
      <Toaster position="top-right" reverseOrder={true} />
      <Context.Provider value={contextValue}>
        <HashRouter>
          <Routes>
            <Route
              path="/"
              element={<Login windowWidth={windowWidth} setAdmin={setAdmin}/>}
            ></Route>

            <Route path="/home" element={<Home />}></Route>
            <Route
              path="/dashboard"
              element={
                <DashBoard
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                />
              }
            ></Route>
            <Route path="/adminPanel" element={<AdminPanel />}></Route>
          </Routes>
        </HashRouter>
      </Context.Provider>
    </div>
  );
}
