import React from "react";
import Header from "./Header";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Spinner, { apiUrl, parseJwt } from "../utils/constants";
import { useContext } from "react";
import { Context } from "../App";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { FcCamera, FcGallery } from "react-icons/fc";
import Webcam from "react-webcam";
export default function DashBoard() {
  return (
    <div className="h-screen w-screen fixed top-0 left-0 right-0 bottm-0 flex flex-col ">
      <div className=" relative w-full h-fit">
        <Header />
      </div>
      <div className="h-[85%] w-full relative overflow-hidden">
        <MainBodyInfo />
      </div>
    </div>
  );
}

function MainBodyInfo() {
  const navigate = useNavigate();

  const [treeIds, setTreeIds] = useState([]);
  const [treeInfo, setTreeInfo] = useState([]);
  const [images, setImages] = useState([]);

  const [loader, setLoader] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [viewAddImage, setViewAddImage] = useState(false);
  const [addNewTree, setAddNewTree] = useState(false);

  const [currentId, setCurrentId] = useState("");
  const { userId, setTreeId, treeId, setUserAgrType, setUploadImage } =
    useContext(Context);
  const { userView, setUserView, isAdmin,setAdmin } = useContext(Context);

  useEffect(() => {
    window.innerWidth < 768 && !userView && setCurrentId("");
    window.innerWidth > 768 && setUserView(false);
  });

  useEffect(() => {
    if (window.innerWidth < 768 && !userView) {
      localStorage.removeItem("treeId");
      setTreeId("");
    }
    
    localStorage.getItem("echo_hex_token") && adminInitializer()
  });

  const adminInitializer=()=>{
    const data = parseJwt(localStorage.getItem("echo_hex_token"));
    if (data.authorities.includes("Admin")
        ||data.authorities.includes("admin")
      ||data.authorities.includes("supersAdmin")) {
      setAdmin(true)}
  }
  useEffect(() => {
    setAddNewTree(false);
    setViewAddImage(false);
    setUserView(false);
    isAdmin ? getTreeIdsHandlerByUserId() : getTreeIdsHandler();
  }, []);

  const getTreeIdsHandlerByUserId = async () => {
    setLoader(true);
    try {
      const response = await axios.get(
        apiUrl +
          `/tree/getTreeId/${userId ? userId : localStorage.getItem("userId")}`,
        {
          headers: { Authorization: localStorage.getItem("echo_hex_token") },
        }
      );
      if (response.data) {
        setTreeIds(response.data ? response.data : null);
        setTreeId(response.data[0].id);
        window.innerWidth > 768 && getTreeByDefault(response.data[0].id);
      }
    } catch (error) {
      setTreeIds([]);
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    }
  };
  const getTreeIdsHandler = async () => {
    setLoader(true);
    try {
      const response = await axios.get(apiUrl + "/tree/getTreeId", {
        headers: { Authorization: localStorage.getItem("echo_hex_token") },
      });
      if (response.data) {
        setTreeIds(response.data ? response.data : null);
        setTreeId(response.data[0].id);
        window.innerWidth > 768 && getTreeByDefault(response.data[0].id);
      }
    } catch (error) {
      toast.error("error! Fetching tree id`s");
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    }
  };

  const getTreeByDefault = async (treeId) => {
    setCurrentId(treeId);
    setLoader(true);
    try {
      await axios
        .get(apiUrl + `/tree/getTree/${treeId}`, {
          headers: {
            Authorization: localStorage.getItem("echo_hex_token"),
          },
        })
        .then((res) => {
          setImageLoading(true);
          try {
            axios
              .get(apiUrl + `/image/get/${treeId}`, {
                headers: {
                  Authorization: localStorage.getItem("echo_hex_token"),
                },
              })
              .then((res) => {
                setImages(res.data);
                setImageLoading(false);
              })
              .catch((error) =>
                toast.error(
                  error.response.data.message +
                    ", statusCode = " +
                    error.response.data.statusCode
                )
              );
          } catch (error) {
            toast.error("Error uploading image:", error);
          }
          setTreeInfo(res.data);
          setTreeId(treeId);
          localStorage.setItem("treeId", treeId);
        })
        .catch((error) =>
          toast.error(
            error.response.data.message +
              ", statusCode = " +
              error.response.data.statusCode
          )
        );
    } catch (error) {
      // error handle
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    }
  };

  const getTreeByOnClickCard = async (treeId) => {
    window.innerWidth < 768 && setUserView(true);
    setCurrentId(treeId);
    setLoader(true);
    try {
      await axios
        .get(apiUrl + `/tree/getTree/${treeId}`, {
          headers: {
            Authorization: localStorage.getItem("echo_hex_token"),
          },
        })
        .then((res) => {
          setImageLoading(true);
          try {
            axios
              .get(apiUrl + `/image/get/${treeId}`, {
                headers: {
                  Authorization: localStorage.getItem("echo_hex_token"),
                },
              })
              .then((res) => {
                setImages(res.data);
                setImageLoading(false);
              })
              .catch((error) =>
                toast.error(
                  error.response.data.message +
                    ", statusCode = " +
                    error.response.data.statusCode
                )
              );
          } catch (error) {
            toast.error("Error uploading image:", error);
          }
          setTreeInfo(res.data);
          setTreeId(treeId);
          localStorage.setItem("treeId", treeId);
        })
        .catch((error) =>
          toast.error(
            error.response.data.message +
              ", statusCode = " +
              error.response.data.statusCode
          )
        );
    } catch (error) {
      // error handle
    } finally {
      setTimeout(() => {
        setLoader(false);
      }, 3000);
    }
  };

  const handleBase64Change = (event) => {
    const inputBase64 = event;
    return inputBase64 && `data:image/jpeg;base64,${inputBase64}`;
  };

  return (
    <div className="h-[100%] md:h-[90%] lg:h-[100%] w-full grid gap-1 md:grid-cols-5 grid-cols-1">
      <div
        className={
          window.innerWidth < 768 && userView
            ? "hidden"
            : " col-span-1 w-full h-[100%] bg-white shadow-lg p-1 flex flex-col gap-2"
        }
      >
        <div className="flex flex-row md:flex-col lg:flex-row font-semibold items-center gap-2 justify-between text-sm">
          <div
            className={`${
              window.innerWidth <= 500 ? "text-[.9rem]" : ""
            } text-center w-full transition-all cursor-pointer text-black-100
        dark:text-white-100 hover:text-white hover:bg-slate-500 bg-gray-300 p-2 px-4 rounded-md font-semibold`}
            onClick={() => {
              setUserAgrType("");
              setTreeId("");
              localStorage.removeItem("user_argr_type");
              localStorage.removeItem("treeId");
              navigate("/home");
            }}
          >
            Home
          </div>
          <div
            className={`${
              window.innerWidth <= 500 ? "text-[.9rem]" : ""
            } text-center w-full transition-all cursor-pointer text-black-100
        dark:text-white-100 hover:text-white hover:bg-slate-500 bg-gray-300 p-2 px-4 rounded-md font-semibold`}
            onClick={() => {
              setAddNewTree(true);
              setUserView(true);
              localStorage.setItem('addNewTree',"addNewTree")
              setTreeId(localStorage.getItem("addNewTree")?localStorage.getItem("addNewTree"):treeId?treeId:localStorage.getItem('treeId'));
            }}
          >
            Add Tree
          </div>
        </div>
        {window.innerWidth < 768 && loader ? (
          <div className="w-full h-[100%] flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="cards_container h-[70vh] md:h-[75vh] lg:h-[75vh]">
            {treeIds
              ? treeIds.map((key, index) => (
                  <div
                    onClick={() => getTreeByOnClickCard(key.id)}
                    className={`border-2 rounded cursor-pointer hover:bg-gray-200 flex flex-col gap-2 h-fit w-full p-1 text-[.7rem] ${
                      currentId === key.id && "card_view_active"
                    }`}
                    key={index}
                  >
                    <div className="w-full flex flex-row gap-2 break-all">
                      <span className="w-[50%] font-semibold">Id</span>
                      <span className="w-[5px]">:</span>
                      <span className="w-[50%] truncate">{key.id}</span>
                    </div>
                    <div className="w-full flex flex-row gap-2 truncate">
                      <span className="w-[50%] font-semibold">KathaNumber</span>
                      <span className="w-[5px]">:</span>
                      <span className="w-[50%] truncate">
                        {key.kathaNumber ? key.kathaNumber : "Null"}
                      </span>
                    </div>
                    <div className="w-full flex flex-row gap-2 truncate">
                      <span className="w-[50%] font-semibold">
                        ServiceNumber
                      </span>
                      <span className="w-[5px]">:</span>
                      <span className="w-[50%] truncate">
                        {key.surveyNumber ? key.surveyNumber : "Null"}
                      </span>
                    </div>
                    <div className="w-full flex flex-row gap-2 truncate">
                      <span className="w-[50%] font-semibold">Trees</span>
                      <span className="w-[5px]">:</span>
                      <span className="w-[50%] truncate">
                        {key.numberOfTrees ? key.numberOfTrees : "Null"}
                      </span>
                    </div>
                  </div>
                ))
              : !loader && (
                  <div className="flex justify-center w-full h-full fons-semibold text-[1rem]">
                    <h1 className="font-semibold text-[1rem]">
                      There is no Tree's
                    </h1>
                  </div>
                )}
          </div>
        )}
      </div>
      <div
        className={` ${
          window.innerWidth < 768 ? !userView && "hidden" : "w-full h-[100%]"
        }  col-span-4 h-[100%] ${
          !userView ? "overflow-auto" : userView && "overflow-auto"
        } w-full  h-[100%] p-1 md:pb-[10%] lg:pb-[0%] overfow-auto scroll_view`}
      >
        {loader ? (
          <div className="relative w-full h-[100%] bg-white flex justify-center items-center">
            <Spinner />
          </div>
        ) : addNewTree ? (
          <div
            className={`w-full h-[100%] flex justify-center  items-center bg-gray-900 bg-opacity-0 flex flex-col gap-2 overflow-hidden`}
          >
            <AddNewTree setAddNewTree={setAddNewTree} />
          </div>
        ) : (
          <div className="w-full h-fit bg-white flex flex-col gap-2">
            <div className="w-full h-fit grid grid-cols-1 gap-1 md:grid-cols-2 lg:grid-cols-3">
              {userView && !loader && (
                <div className="flex flex-row justify-between w-full">
                  <div></div>

                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        setUserView(false);
                        setTreeId("");
                        localStorage.removeItem("treeId");
                        setCurrentId("");
                      }}
                      className=" rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset"
                    >
                      <span className="sr-only">Close menu</span>
                      <svg
                        className="h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
              {!loader &&
                treeInfo &&
                Object.keys(treeInfo).map((key, index) => (
                  <div
                    key={index}
                    className={
                      window.innerWidth > 430
                        ? "text-[.8rem] p-1 shadow-lg  h-fit  w-full grid grid-cols-5 gap-1 items-center justify-between border-gray-400 rounded-lg"
                        : "text-[.9rem] p-1 shodow-lg h-fit w-full flex flex-col gap-1 border-gray-400 rounded-lg"
                    }
                  >
                    {/* tree label names */}
                    <div
                      title={key}
                      className="truncate capitalize font-semibold text-[.8rem] col-span-2 p-1 h-full w-full border-2 flex items-center rounded"
                    >
                      {key ? key : "Null"}
                    </div>
                    {/* tree values */}
                    <div
                      title={treeInfo[key]}
                      className=" col-span-3 text-[.8rem] p-1 w-full h-full border-2 flex items-center rounded"
                    >
                      {treeInfo[key] ? treeInfo[key] : "Null"}
                    </div>
                  </div>
                ))}
            </div>
            <div className="w-full h-fit flex flex-col gap-2 border-2 border-slate-400 rounded p-1">
              <div className="w-full flex justify-center font-semibold md:text-[1.5rem] underline">
                <h1>
                  {treeInfo && imageLoading
                    ? "Please wait loading Images"
                    : images.length > 0
                    ? "Tree Images"
                    : "No Tree Images"}
                </h1>
              </div>
              <div
                className={`w-full h-fit grid ${
                  window.innerWidth < 500 ? "grid-cols-1" : "grid-cols-2"
                } gap-1 md:grid-cols-2 lg:grid-cols-3`}
              >
                {!loader &&
                  images &&
                  images.map((key, index) => (
                    <div
                      className="bg-white w-full h-fit shadow-2xl border-2 border-gray-300 rounded-lg"
                      key={index}
                    >
                      <div className="flex flex-col w-full items-center">
                        <div className=" w-fit h-fit">
                          <img
                            className="aspect-auto"
                            src={handleBase64Change(key.file && key.file)}
                            alt="Converted"
                          />
                        </div>
                        <div className="w-full h-fit  flex flex-row items-center justify-between text-[0.8rem] p-2">
                          <div className="flex flex-col justify-between w-[100%] gap-2">
                            <div className="w-full flex flex-row gap-2 break-all">
                              <span className="w-[30%] font-semibold">
                                Time
                              </span>
                              <span className="w-[5px]">:</span>
                              <span className="w-[70%]">
                                {key.date ? key.date : "Null"}
                              </span>
                            </div>
                            <div className="w-full flex flex-row gap-2 break-all">
                              <span className="w-[30%] font-semibold">
                                Location
                              </span>
                              <span className="w-[5px]">:</span>
                              <span className="w-[70%]">
                                <span title="latitude">
                                  {key.latitude ? key.latitude : "Null"}
                                </span>
                                {","}
                                <span title="longitude">
                                  {key.longitude ? key.longitude : "Null"}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {!loader && (
              <div
                onClick={() => {
                  setViewAddImage(true);
                  setUploadImage('addImage')
                }}
                className="h-fit w-fit bg-slate-200 hover:bg-slate-500 hover:text-white font-semibold p-2 rounded-lg cursor-pointer fixed right-1 bottom-2"
              >
                Upload Image
              </div>
            )}
          </div>
        )}
      </div>
      {/* add new Image popup */}
      {viewAddImage && (
        <div className="fixed w-screen h-screen top-0 flex items-center justify-center">
          <ImageController setViewAddImage={setViewAddImage} />
        </div>
      )}
    </div>
  );
}

export const AddNewTree = ({ setAddNewTree }) => {
  const label_style =
    " w-full block mb-2 text-sm font-medium text-gray-900 dark:text-black";
  const input_style =
    "w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";
  const { setUserView } = useContext(Context);
  const [ageOfTheTree, setAgeOfTheTree] = useState(0.0);
  const [numberOfTrees, setNumberOfTrees] = useState(0.0);
  const [kathaNumber, setKathaNumber] = useState("");
  const [surveyNumber, setSurveyNumber] = useState("");
  const [landExtent, setLandExtent] = useState(0.0);
  const [landType, setLandType] = useState("");
  const [height, setHeight] = useState(0.0);
  const [steamDiameter, setSteamDiameter] = useState(0.0);

  const addTreeHandler = async () => {
    if (steamDiameter !== 0 && surveyNumber !== null && kathaNumber !== null) {
      try {
        const res = await axios.post(
          apiUrl + "/tree/add",
          {
            ageOfTheTree: ageOfTheTree,
            numberOfTrees: numberOfTrees,
            kathaNumber: kathaNumber,
            surveyNumber: surveyNumber,
            landExtent: landExtent,
            landType: landType,
            height: height,
            steamDiameter: steamDiameter,
          },
          {
            headers: {
              Authorization: localStorage.getItem("echo_hex_token"),
            },
          }
        );
        if (res) {
          toast.success("Successfully New Tree Added");
          setAddNewTree(false);
          setUserView(false);
          window.location.reload(false);
        }
      } catch (error) {
        toast.error("error! while New Tree Added");
      }
    } else {
      toast.error("error! tree data must not be null");
    }
  };

  return (
    <div className="flex w-full h-[100%] md:w-[50%] lg:w-[40%] flex-col gap-4 rounded-lg bg-slate-100 p-2 pt-1 text-[1.2rem]">
      {/* header form */}
      <div className="flex flex-row justify-between border-b border-gray">
        {/* <div></div> */}
        <div className="text-gray-800 text-[1.5rem]">Add New Tree</div>
        <div>
          <button
            type="button"
            onClick={() => {
              setAddNewTree(false);
              setUserView(false);
              localStorage.removeItem('addNewTree')
            }}
            className=" rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset"
          >
            <span className="sr-only">Close menu</span>
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* body form */}
      <div
        className="h-[100%] w-[100%] flex flex-col gap-2 items-center
            overflow-auto scrollbar-thin scrollbar-thumb-gray-700 scrollbar-thumb-rounded
            scrollbar-track-transparent"
      >
        {/* ageOfTheTree */}
        <div className="w-[90%]">
          <label htmlFor="ageOfTheTree" className={label_style}>
            Age Of The Tree (years)
          </label>
          <input
            type="number"
            id="ageOfTheTree"
            className={input_style}
            placeholder="Enter Age Of The Tree"
            value={ageOfTheTree}
            onChange={(e) => setAgeOfTheTree(e.target.value)}
          />
        </div>

        {/* numberOfTrees */}
        <div className="w-[90%]">
          <label htmlFor="numberOfTrees" className={label_style}>
            Number Of Trees
          </label>
          <input
            type="number"
            id="numberOfTrees"
            className={input_style}
            placeholder="Enter Number Of Trees"
            value={numberOfTrees}
            onChange={(e) => setNumberOfTrees(e.target.value)}
          />
        </div>

        {/* kathaNumber */}
        <div className="w-[90%]">
          <label htmlFor="kathaNumber" className={label_style}>
            Katha Number
          </label>
          <input
            type="text"
            id="kathaNumber"
            className={input_style}
            placeholder="Enter Katha Number"
            value={kathaNumber}
            onChange={(e) => setKathaNumber(e.target.value)}
          />
        </div>

        {/* surveyNumber */}
        <div className="w-[90%]">
          <label htmlFor="surveyNumber" className={label_style}>
            Survey Number
          </label>
          <input
            type="text"
            id="surveyNumber"
            className={input_style}
            placeholder="Enter Survey Numberr"
            value={surveyNumber}
            onChange={(e) => setSurveyNumber(e.target.value)}
          />
        </div>

        {/* landExtent */}
        <div className="w-[90%]">
          <label htmlFor="landExtent" className={label_style}>
            Land Extent
          </label>
          <input
            type="number"
            id="landExtent"
            className={input_style}
            placeholder="Enter Land Extent"
            value={landExtent}
            onChange={(e) => setLandExtent(e.target.value)}
          />
        </div>

        {/* landType */}
        <div className="w-[90%]">
          <label htmlFor="landType" className={label_style}>
            Land Type
          </label>
          <input
            type="text"
            id="landType"
            className={input_style}
            placeholder="Enter Land Type"
            value={landType}
            onChange={(e) => setLandType(e.target.value)}
          />
        </div>
        {/* height */}
        <div className="w-[90%]">
          <label htmlFor="height" className={label_style}>
            Height (meters)
          </label>
          <input
            type="number"
            id="height"
            className={input_style}
            placeholder="Enter Height (m)"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
        </div>

        {/* stram diameter */}
        <div className="w-[90%]">
          <label htmlFor="steamDiameter" className={label_style}>
            Steam Diameter (centi meters)
          </label>
          <input
            type="number"
            id="steamDiameter"
            className={input_style}
            placeholder="Enter Steam Diameter (cm)"
            value={steamDiameter}
            onChange={(e) => setSteamDiameter(e.target.value)}
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={addTreeHandler}
          className="bg-gradient-to-r from-green-300 to-blue-400 hover:from-pink-200 hover:to-yellow-200 pl-10 rounded-lg pr-10 p-2"
        >
          Add Tree
        </button>
      </div>
    </div>
  );
};

function ImageController({ setViewAddImage }) {
  const [selectedFile, setSelectedFile] = useState("");
  const [preViewImage, setPreViewImage] = useState("");

  const [isCamera, setCamera] = useState(false);
  const { treeId, setUploadImage } = useContext(Context);
  const [latitude, setLatitude] = useState(0.0);
  const [longitude, setLongitude] = useState(0.0);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    });
  });

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("treeId", treeId);

    try {
      await axios
        .post(apiUrl + "/image/upload", formData, {
          headers: {
            Authorization: localStorage.getItem("echo_hex_token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          toast.success("Image uploaded successfully id: " + res.data);
          setViewAddImage(false);
          window.location.reload(false);
        })
        .catch((error) => {
          toast.error(
            error.response.data.message +
              ", statusCode = " +
              error.response.data.statusCode
          );
        });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };
  const uploadError = () => {
    toast.error("error! please choose image");
  };

  const convertToBase64 = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setPreViewImage(reader.result);
      setSelectedFile(e.target.files[0]);
    };
    reader.onerror = (error) => {
      toast.error("Error: ", error);
    };
  };

  return (
    <div className="w-screen h-screen  bg-gray-900 bg-opacity-25 flex items-center justify-center p-1">
      <div className=" min-w-[400px] p-2 w-fit lg:w-[500px] min:h-[200px] max-h-fit bg-white shadow-2xl rounded-lg flex flex-col gap-2">
        <div className="w-full md:text-lg font-semibold flex flex-row">
          <h1 className="w-[98%] text-center ml-10 ">Upload Tree Image</h1>
          <button
            type="button"
            onClick={() => {
              setViewAddImage(false);
              setUploadImage('')
            }}
            className=" rounded-md pl-1 pr-1 inline-flex items-center justify-center text-gray-800 hover:text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span className="sr-only">Close menu</span>
            <svg
              className="h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col w-full gap-2">
          {!preViewImage && !isCamera && (
            <div className="h-full w-full flex flex-row items-center justify-center gap-10 p-4">
              <div className="flex flex-col items-center justify-center">
                <label
                  htmlFor="file-manager"
                  className="cursor-pointer hover:bg-slate-200 p-1 rounded-lg"
                >
                  <FcGallery size={50} />
                </label>
                <input
                  type="file"
                  id="file-manager"
                  name="file-manager"
                  accept="image/*"
                  onChange={convertToBase64}
                  className="hidden"
                />
                <label>Select from Gallery</label>
              </div>
              <div className="flex flex-col items-center justify-center">
                <label
                  className="cursor-pointer hover:bg-slate-200 p-1 rounded-lg"
                  onClick={() => setCamera(true)}
                >
                  <FcCamera size={50} />
                </label>
                <label>Take a Photo</label>
              </div>
            </div>
          )}

          {preViewImage && (
            <div className="flex justify-center w-full p-2 h-[300px]">
              <img
                className="aspect-auto h-full w-full rounded-md"
                src={preViewImage}
                alt="previewImage"
              />
            </div>
          )}
          {isCamera && (
            <WebcamCapture
              setCamera={setCamera}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              setPreViewImage={setPreViewImage}
            />
          )}
          <div className="w-full flex flex-row justify-center gap-10">
            {preViewImage && (
              <button
                className="w-fit h-fit p-1 pr-8 rounded pl-8 bg-slate-300 hover:bg-slate-500 hover:text-white"
                onClick={() => {
                  setSelectedFile("");
                  setPreViewImage("");
                }}
              >
                Clear
              </button>
            )}
            {!isCamera && (
              <button
                className="w-fit h-fit p-1 pr-4 rounded pl-4 bg-slate-300 hover:bg-slate-500 hover:text-white"
                onClick={() => (selectedFile ? handleUpload() : uploadError())}
              >
                Upload Image
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const WebcamCapture = ({
  setCamera,
  selectedFile,
  setSelectedFile,
  setPreViewImage,
}) => {
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const blob = await fetch(imageSrc).then((res) => res.blob());
    setSelectedFile(blob);
    setPreViewImage(imageSrc);
    setCamera(false);
  });
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  return (
    <div className="w-full h-full flex flex-col gap-2 items-center ">
      <div className="webcam-img">
        {selectedFile === "" && (
          <Webcam
            audio={false}
            height={550}
            ref={webcamRef}
            screenshotFormat="file"
            accept="image/*"
            width={750}
            videoConstraints={videoConstraints}
          />
        )}
      </div>
      <div>
        {selectedFile === "" && (
          <button
            onClick={(e) => {
              e.preventDefault();
              capture();
            }}
            className="w-fit h-fit p-1 pr-4 rounded pl-4 bg-slate-300 hover:bg-slate-500 hover:text-white"
          >
            Capture
          </button>
        )}
      </div>
    </div>
  );
};
