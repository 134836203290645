import React from "react";
import { useNavigate } from "react-router-dom";
import { parseJwt } from "../utils/constants";
import { toast } from "react-hot-toast";
import { useContext } from "react";
import { Context } from "../App";

export default function Header() {
  const {isAdmin}=useContext(Context)
  const {setUserView,setUserSelectView}=useContext(Context)

  const {userName,
    setUserName,
    setUserId,
    userSelect,
    uploadImage,
    setUploadImage,
    setUserSelect,
    userAgrType,
    setUserAgrType,
    treeId,
    setTreeId}=useContext(Context)
    

    

  const navigate = useNavigate();
  const onsignOut = () => {
    localStorage.clear();
    toast.success("Successfully SignOut");
    navigate("/");
  };
  const homeHandler = () => {
    if(window.innerWidth<768){
      setUserName("")
    setUserId("")
    localStorage.removeItem('name')
    localStorage.removeItem('userId')
    }
    setUserSelect("")
    setUserAgrType("")
    setTreeId("")
    setUserView(false)
    setUserSelectView(false)
    setUploadImage('')
    localStorage.removeItem('user_select')
    localStorage.removeItem('user_argr_type')
    localStorage.removeItem('treeId')

    navigate("/adminPanel");
  };
  const userViewHandler=()=>{
    setUserSelect("")
    setUserAgrType("")
    setTreeId("")
    setUploadImage('')
    localStorage.removeItem('user_select')
    localStorage.removeItem('user_argr_type')
    localStorage.removeItem('treeId')

    if(window.innerWidth<768){
    setUserSelectView(false)
    setUserView(true)
    }
    navigate("/adminPanel");
  }
  const userAssetsHandler = () => {
    setUserAgrType("")
    setUploadImage('')
    setTreeId("")
    localStorage.removeItem('user_argr_type')
    localStorage.removeItem('treeId')
     navigate("/home");
  };
  const assetsHandler=()=>{
    if(window.innerWidth<768){
      setTreeId("")
      setUserView(false)
    localStorage.removeItem('treeId')
    setUploadImage('')
    }  
  }
  
  return (
    <div className="header">
      <div className="header_logo">
        <img src={require("../assets/EcoHex Logo.png")} alt="logo-ecohex" />
      </div>
      <div className="flex flex-col w-full h-full">
        <div className="header_content">
          <div className={window.innerWidth <= 500 ? "text-[.9rem]" : ""}>
            {window.innerWidth <= 350 ? "Hi, " : "Welcome to EcoHex, "}
            <strong>
              {parseJwt(localStorage.getItem("echo_hex_token")).name
                ? parseJwt(localStorage.getItem("echo_hex_token")).name
                : "User"}
            </strong>
          </div>
          <div
            className={`${
              window.innerWidth <= 500 ? "text-[.9rem]" : ""
            } transition-all cursor-pointer text-black-100
        dark:text-white-100 dark:hover:bg-slate-200 hover:bg-gray-200 p-2 px-4 rounded-md font-semibold`}
            onClick={onsignOut}
            title="click to signout"
          >
            SignOut
          </div>
        </div>
        <>
          {isAdmin && (
            <div className="w-full h-full">
              <span
                className="hover:text-red-500 hover:underline hover:cursor-pointer"
                onClick={() => homeHandler()}
              >
                Home
              </span>
              <span>{">"}</span>
              {isAdmin && userName &&(
                <>
                  <span className="hover:text-red-500 hover:underline hover:cursor-pointer" onClick={()=>userViewHandler()}>
                  {userName}
                  </span>
                  <span>{">"}</span>
                </>
              )}
               {userName && userSelect && (
               <>
                <span className="hover:text-red-500 hover:underline hover:cursor-pointer" onClick={()=>userSelect==='assets'&&userAssetsHandler()}>
                  {userSelect}
                </span>
                <span>{">"}</span></>
              )}
              { userSelect && userAgrType && (
               <>
                <span onClick={()=>window.innerWidth<768&&assetsHandler()} className="hover:text-red-500 hover:underline hover:cursor-pointer">
                  {userAgrType}
                </span>
                <span>{">"}</span></>
              )}
              {userAgrType && treeId &&
                <span className="hover:text-red-500 hover:underline hover:cursor-pointer">
                  {treeId?treeId:localStorage.getItem(treeId)}
                </span>
              }
               {treeId && uploadImage &&
                <span className="hover:text-red-500 hover:underline hover:cursor-pointer">
                  <span>{">"}</span>{uploadImage}
                </span>
              }
            </div>
          )}
        </>
      </div>
    </div>
  );
}
