import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import Spinner, { apiUrl } from "../utils/constants";
import { toast } from "react-hot-toast";
import axios from "axios";
import { FaUserPlus } from "react-icons/fa";
import { useContext } from "react";
import { Context } from "../App";

export default function AdminPanel() {
  return (
    <div className="h-screen w-screen fixed top-0 left-0 right-0 bottm-0 flex flex-col ">
      <div className=" relative w-full h-fit">
        <Header />
      </div>
      <div className="h-full w-full relative overflow-hidden">
        <AdminPanelBody />
      </div>
    </div>
  );
}

function AdminPanelBody() {
  const navigate = useNavigate();

  const [userIds, setUserIds] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  const [loader, setLoader] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentId, setCurrentId] = useState("");

  const { userView, setUserView } = useContext(Context);
  const { userSelectView, setUserSelectView } = useContext(Context);

  const { setUserName, setUserSelect } = useContext(Context);

  const initializer = () => {
    setUserInfo(null);
    setLoader(false);
    setSearchTerm("");
    setUserSelectView(false);
  };
  useEffect(() => {
    window.innerWidth < 768 && !userView && setCurrentId("");
    window.innerWidth > 768 && setUserView(false) && setUserSelectView(false);
    if (window.innerWidth > 400 && window.innerWidth < 768) {
      setNewUser(true);
    } else {
      setNewUser(false);
    }
  });

  useEffect(() => {
    initializer();
    setLoader(true);
    try {
      axios
        .get(apiUrl + "/user/getUserIds", {
          headers: { Authorization: localStorage.getItem("echo_hex_token") },
        })
        .then((res) => {
          setUserIds(res.data);
          window.innerWidth > 768 && getUserByDefault(res.data[0].id);
        })
        .catch((error) => {
          toast.error(
            error.response.data.message +
              ", statusCode = " +
              error.response.data.statusCode
          );
        })
        .finally(
          setTimeout(() => {
            setLoader(false);
          }, 3000)
        );
    } catch (error) {
      toast.error("error! fetching user Id's");
    }
  }, []);
  
  useEffect(() => {
    if (window.innerWidth < 768 && !userView) {
      localStorage.removeItem("name");
      setUserName("");
    }
  });

  const getUserByDefault = (userId) => {
    setCurrentId(userId);
    setLoader(true);
    try {
      axios
        .get(apiUrl + `/user/get/${userId}`, {
          headers: { Authorization: localStorage.getItem("echo_hex_token") },
        })
        .then((res) => {
          setUserInfo(res.data);
          setUserName(res.data.name ? res.data.name : "User");
          localStorage.setItem("name", res.data.name ? res.data.name : "User");
          localStorage.setItem("userId", userId);
        })
        .catch((error) => {
          toast.error(
            error.response.data.message +
              ", statusCode = " +
              error.response.data.statusCode
          );
        });
    } catch (error) {
      toast.error("error! getting user details");
    } finally {
      setTimeout(() => {
        setLoader(false);
        setSearchTerm("");
      }, 3000);
    }
  };

  const getUserDataHandler = (userId) => {
    window.innerWidth < 768 && setUserView(true);
    setCurrentId(userId);
    setLoader(true);
    try {
      axios
        .get(apiUrl + `/user/get/${userId}`, {
          headers: { Authorization: localStorage.getItem("echo_hex_token") },
        })
        .then((res) => {
          setUserInfo(res.data);
          setUserName(res.data.name ? res.data.name : "User");
          localStorage.setItem("name", res.data.name ? res.data.name : "User");
          localStorage.setItem("userId", userId);
        })
        .catch((error) => {
          toast.error(
            error.response.data.message +
              ", statusCode = " +
              error.response.data.statusCode
          );
        });
    } catch (error) {
      toast.error("error! getting user details");
    } finally {
      setTimeout(() => {
        setLoader(false);
        setSearchTerm("");
      }, 3000);
    }
  };

  return (
    <div className="h-[100%] w-full grid gap-1 md:grid-cols-5 grid-cols-1 mt-2 pb-3">
      <div
        className={
          window.innerWidth < 768 && userView
            ? "hidden"
            : "h-[100%] col-span-1 w-full h-[100%] bg-white shadow-lg p-1 flex flex-col gap-2"
        }
      >
        <div className="flex flex-row gap-1 w-full h-fit text-sm">
          <div className="w-full">
            <input
              className=" w-full bg-slate-300  text-black-500 p-2 rounded-md font-semibold"
              type="search"
              placeholder="search user"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {
          newUser && 
          <div className="flex flex-row justify-center items-center gap-2 p-2 h-fit w-full bg-gray-300 cursor-pointer hover:bg-gray-200 rounded">
          <FaUserPlus size={18} />
          <span>Add New User</span>
        </div>
        }
        </div>
        {window.innerWidth < 768 && loader ? (
          <div className="w-full h-[100%] flex justify-center items-center">
            <Spinner />
          </div>
        ) : (
          <div className="cards_container md:h-[65vh] h-[65vh] overflow-auto md:pb-[20%]">
            {
            userIds
              ? userIds
                  .filter((item) => {
                    const searchFields = [item.id, item.name, item.role]; // Add more fields as needed
                    return searchFields.some(
                      (field) =>
                        field &&
                        field.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                  })
                  .map((key, index) => (
                    <div
                      onClick={() => getUserDataHandler(key.id)}
                      className={`border-2 h-fit rounded cursor-pointer hover:bg-gray-200 flex flex-col gap-2 h-fit w-full p-1 text-[.7rem] ${
                        currentId === key.id && "card_view_active"
                      }`}
                      key={index}
                    >
                      <div className="w-full flex flex-row gap-2 break-all">
                        <span className="w-[40%] font-semibold">Id</span>
                        <span className="w-[5px]">:</span>
                        <span className="w-[60%]">{key.id}</span>
                      </div>
                      <div className="w-full flex flex-row gap-2 truncate">
                        <span className="w-[40%] font-semibold">Name</span>
                        <span className="w-[5px]">:</span>
                        <span className="w-[60%]">
                          {key.name ? key.name : "Null"}
                        </span>
                      </div>
                      <div className="w-full flex flex-row gap-2 break-all">
                        <span className="w-[40%] font-semibold">Role</span>
                        <span className="w-[5px]">:</span>
                        <span className="w-[60%]">{key.role}</span>
                      </div>
                    </div>
                  ))
              : ""}
              
            
          </div>
        )}
        {
          !newUser && 
          <div className="flex flex-row justify-center items-center gap-2 p-2 h-fit w-full bg-gray-300 cursor-pointer hover:bg-gray-200 rounded">
          <FaUserPlus size={18} />
          <span>Add New User</span>
        </div>
        }
      </div>
      <div
        className={` ${
          window.innerWidth < 768 ? !userView && "hidden" : "w-full h-full"
        }  col-span-4 h-full ${
          !userView
            ? "overflow-auto"
            : userView && userSelectView && "overflow-auto"
        } w-full`}
      >
        {loader ? (
          <div className="relative w-full h-full bg-white flex justify-center items-center">
            <Spinner />
          </div>
        ) : userSelectView ? (
          <div className="w-full h-full bg-white flex flex-col gap-2 ">
            {!loader && (
              <div className="flex flex-row justify-between w-full">
                <div></div>
                <button
                  onClick={() => {
                    setUserSelectView(false);
                    setUserSelect(null);
                    localStorage.removeItem("user_select");
                  }}
                  type="button"
                  className=" rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset"
                  >
                  <span className="sr-only">Close menu</span>
                  <svg
                    className="h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            )}
            <div className="w-full h-fit grid grid-cols-1 gap-1 md:grid-cols-2 lg:grid-cols-3">
              {!loader &&
                userInfo &&
                Object.keys(userInfo).map((key, index) => (
                  <div
                    key={index}
                    className={
                      window.innerWidth > 430
                        ? "text-[.8rem] p-1 shadow-lg  h-fit  w-full grid grid-cols-5 gap-1 items-center justify-between border-gray-400 rounded-lg"
                        : "text-[.9rem] p-1 shodow-lg h-fit w-full flex flex-col gap-1 border-gray-400 rounded-lg"
                    }
                  >
                    {/* user label names */}
                    <div
                      title={key}
                      className="truncate capitalize font-semibold text-[.8rem] col-span-2 p-1 h-full w-full border-2 flex items-center rounded"
                    >
                      {key ? key : "Null"}
                    </div>
                    {/* user values */}
                    <div
                      title={userInfo[key]}
                      className=" col-span-3 text-[.8rem] p-1 w-full h-full border-2 flex items-center rounded"
                    >
                      {userInfo[key] ? userInfo[key] : "Null"}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          <div className="w-full h-[75vh] ">
            {userView && (
              <div className="flex flex-row h-fit  w-full justify-between ">
                <div></div>
                <div>
                  {" "}
                  <button
                    onClick={() => {
                      setUserView(false);
                      setUserName(null);
                      localStorage.removeItem("name");
                    }}
                    type="button"
                    className=" rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-inset"
                    >
                    <span className="sr-only">Close menu</span>
                    <svg
                      className="h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            )}
            <div className="w-full h-full flex items-center overflow-hidden justify-center">
              <div className="w-fit h-fit grid grid-col-1 md:grid-cols-2 gap-2 items-center justify-center">
                <div
                  onClick={() => {
                    setUserSelectView(true);
                    setUserSelect("Info");
                    localStorage.setItem("user_select", "Info");
                    getUserDataHandler(localStorage.getItem("userId"));
                  }}
                  className="col-span-1 w-[300px] h-[150px] bg-gray-300 hover:bg-slate-500 hover:text-white font-semibold ease-in duration-700 rounded flex items-center justify-center cursor-pointer"
                >
                  <h1>View User Info</h1>
                </div>
                <div
                  onClick={() => {
                    navigate("/home");
                    setUserSelect("assets");
                    localStorage.setItem("user_select", "assets");
                  }}
                  className="col-span-1 w-[300px] h-[150px] bg-gray-300 hover:bg-slate-500 hover:text-white font-semibold ease-in duration-700 rounded flex items-center justify-center cursor-pointer"
                >
                  <h1>View User Assets</h1>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
