import React, {  useState } from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { toast } from "react-hot-toast";
import { useContext } from "react";
import { Context } from "../App";

export default function Home() {

  const navigate = useNavigate();
  const [treeLoader, setTreeLoader] = useState(false);
  const [cropLoader, setCropLoader] = useState(false);
  const {setUserAgrType}=useContext(Context)
  const treeNavigateHandler = (page) => {
    if(page==="tree") setTreeLoader(true)
    else if(page==="crop") setCropLoader(true)
    setTimeout(() => {
        if(page==="tree"){
         localStorage.setItem('user_argr_type',"Trees")
         setUserAgrType("Trees")
          navigate("/dashboard");
          toast.success("Welcome to the your dashboard")
        } 
        else if(page==="crop") {
          setUserAgrType("Crops")
          localStorage.setItem('user_argr_type',"Crops")
        }
        
      setCropLoader(false);
      setTreeLoader(false)
    }, 3000);
  };

  return (
    <div className="h-screen w-screen fixed">
      <Header />
      <div className="home_cards_container">
        <div onClick={() => treeNavigateHandler("tree")}>
          {treeLoader && (
            <nav className="progress_loader">
              <span><CircularProgress  size={20}sx={{color:'black'}}/></span>
              <span>Please wait...</span>
            </nav>
          )}
          <img src={require("../assets/Trees_image.png")} alt="tree_logo" />
        </div>
        <div onClick={() => treeNavigateHandler("crop")}>
        {cropLoader && (
            <nav className="progress_loader">
              <span><CircularProgress  size={20}sx={{color:'black'}}/></span>
              <span>Please wait...</span>
            </nav>
          )}
          <img src={require("../assets/crops_image.jpg")} alt="crops_logo" />
        </div>
      </div>
    </div>
  );
}
